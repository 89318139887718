import { readonly, ref } from 'vue'

const rules = ref('')
const personalDataProcessing = ref('')

export const useRules = () => {
  const setProgramRules = (path: string) => {
    rules.value = path
  }

  const setPersonalDataProcessing = (path: string) => {
    personalDataProcessing.value = path
  }

  return {
    rules: readonly(rules),
    personalDataProcessing: readonly(personalDataProcessing),
    setProgramRules,
    setPersonalDataProcessing,
  }
}
