import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const promotionsListAccess = computed(() => {
  return checkPermissionHandler('promotions', 'promotionListAdmin')
})

const promotionAccess = computed(() => {
  return checkPermissionHandler('promotions', 'promotionDetailAdmin')
})

const promotionsUserListAccess = computed(() => {
  return checkPermissionHandler('promotions', 'promotionListUser')
})

const promotionUserAccess = computed(() => {
  return checkPermissionHandler('promotions', 'promotionListUser')
})

const promotionDetailUserAccess = computed(() => {
  return checkPermissionHandler('promotions', 'promotionDetailUser')
})

export const promotionsFactory = () => {
  return {
    promotionsListAccess,
    promotionAccess,
    promotionsUserListAccess,
    promotionUserAccess,
    promotionDetailUserAccess,
  }
}
