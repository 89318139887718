import { computed, readonly, ref } from 'vue'
import { ProfileDataType, UserDataType } from '@/api/UserService/UserService.type'
import { ROUTE_NAMES } from '@/constants'

const isAuthorized = ref(false)
const user = ref<UserDataType>()
const profile = ref<ProfileDataType>()
const userMainPage = computed(() => {
  if (profile.value) {
    if (profile.value.role === 'member') {
      return ROUTE_NAMES.Dashboard
    }
  }

  return ROUTE_NAMES.AdminDashboard
})

export const useUser = () => {
  const setUser = (userData: UserDataType | undefined) => {
    user.value = userData
  }

  const setProfile = (profileData: ProfileDataType | undefined) => {
    profile.value = profileData
  }

  const setAuthorized = (value: boolean) => {
    isAuthorized.value = value
  }

  const pagePermissions = computed(() => {
    if (user.value?.items.length) {
      const result: { [x: string]: UserDataType['items'][number]['routeGroups'] } = {}

      user.value?.items.forEach((item) => {
        result[item.code] = item.routeGroups
      })

      return result
    }

    return undefined
  })

  return {
    user: readonly(user),
    profile: readonly(profile),
    isAuthorized: readonly(isAuthorized),
    pagePermissions,
    setAuthorized,
    setUser,
    setProfile,
    userMainPage: readonly(userMainPage),
  }
}
