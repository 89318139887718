import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const brandsListAccess = computed(() => {
  return checkPermissionHandler('brands', 'brandsList')
})

const brandsAccess = computed(() => {
  return checkPermissionHandler('brands', 'brands')
})

export const brandsFactory = () => {
  return {
    brandsListAccess: brandsListAccess,
    brandsAccess: brandsAccess,
  }
}
