import { AxiosRequestConfig } from 'axios'
import { API_CONFIG, AxiosService } from '@/plugins/axiosService'
import { SocialsType } from '@/api/AppSettingsService/AppSettingsService.type'

class AppSettingsApi extends AxiosService {
  constructor(config?: AxiosRequestConfig) {
    super(config)
  }

  // соцсети
  getSocials = async () => {
    return await this.axiosCall<{ items: Partial<SocialsType> }>({
      method: 'get',
      url: '/v1/public/backoffice/settings/social',
    })
  }

  getOferta = async () => {
    return await this.axiosCall<{ file: string }>({
      method: 'get',
      url: '/v1/public/backoffice/settings/oferta',
    })
  }

  addSocials = async (payload: Partial<SocialsType>) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/backoffice/settings/social',
      data: payload,
    })
  }

  editSocials = async (payload: Partial<SocialsType>) => {
    return await this.axiosCall({
      method: 'put',
      url: '/v1/private/backoffice/settings/social',
      data: payload,
    })
  }

  deleteSocials = async (payload: string) => {
    return await this.axiosCall({
      method: 'delete',
      url: `/v1/private/backoffice/settings/social/${payload}`,
    })
  }

  // Правила программы
  getProgramRules = async () => {
    return await this.axiosCall<{ file: string }>({
      method: 'get',
      url: '/v1/public/backoffice/settings/program-rules-file',
    })
  }

  addProgramRules = async (payload: FormData) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/backoffice/settings/program-rules-file/add',
      data: payload,
    })
  }

  editProgramRules = async (payload: FormData) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/backoffice/settings/program-rules-file/update',
      data: payload,
    })
  }

  deleteProgramRules = async () => {
    return await this.axiosCall({
      method: 'delete',
      url: '/v1/private/backoffice/settings/program-rules-file',
    })
  }

  // Обработка персональных данных
  getPersonalDataProcessing = async () => {
    return await this.axiosCall<{ file: string }>({
      method: 'get',
      url: '/v1/public/backoffice/settings/personal-data-processing-file',
    })
  }

  addPersonalDataProcessing = async (payload: FormData) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/backoffice/settings/personal-data-processing-file/add',
      data: payload,
    })
  }

  editPersonalDataProcessing = async (payload: FormData) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/backoffice/settings/personal-data-processing-file/update',
      data: payload,
    })
  }

  deletePersonalDataProcessing = async () => {
    return await this.axiosCall({
      method: 'delete',
      url: '/v1/private/backoffice/settings/personal-data-processing-file',
    })
  }

  // обработка Оферты
  getOfertaData = async () => {
    return await this.axiosCall<{ file: string }>({
      method: 'get',
      url: '/v1/public/backoffice/settings/oferta',
    })
  }

  addOfertaFile = async (payload: FormData) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/backoffice/settings/oferta/add',
      data: payload,
    })
  }

  editOfertaFile = async (payload: FormData) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/backoffice/settings/oferta/update',
      data: payload,
    })
  }

  deleteOfertaFile = async () => {
    return await this.axiosCall({
      method: 'delete',
      url: '/v1/private/backoffice/settings/oferta',
    })
  }
}

export default new AppSettingsApi(API_CONFIG)
