import { createRouter, createWebHistory } from 'vue-router'
import { ROUTE_NAMES } from '@/constants'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/MainLayout/MainLayout.vue'),
      redirect: { name: ROUTE_NAMES.Profile },
      children: [
        {
          path: 'login',
          component: () => import('@/pages/SignIn/SignIn.vue'),
          name: ROUTE_NAMES.Login,
        },
        {
          path: 'registration',
          component: () => import('@/pages/Registration/UserRegistration.vue'),
          name: ROUTE_NAMES.Registration,
        },
        {
          path: 'restore-password',
          component: () => import('@/pages/RestorePassword/RestorePassword.vue'),
          name: ROUTE_NAMES.RestorePassword,
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/layouts/DefaultLayout/DefaultLayout.vue'),
      children: [
        {
          path: 'profile',
          component: () => import('@/pages/clientsPages/UserProfile/UserProfile.vue'),
          name: ROUTE_NAMES.Profile,
        },
        {
          path: 'nomenclature',
          component: () => import('@/pages/clientsPages/NomenclaturePage/NomenclaturePage.vue'),
          name: ROUTE_NAMES.Nomenclature,
        },
        {
          path: 'promotions',
          component: () => import('@/pages/clientsPages/PromotionsPage/PromotionsPage.vue'),
          name: ROUTE_NAMES.Promotions,
        },
        {
          path: 'promotion-detail/:id',
          component: () => import('@/pages/clientsPages/PromotionDetailPage/PromotionDetailPage.vue'),
          name: ROUTE_NAMES.PromotionDetail,
        },
        {
          path: 'dashboard',
          component: () => import('@/pages/clientsPages/UserDashboard/UserDashboard.vue'),
          name: ROUTE_NAMES.Dashboard,
        },
        {
          path: 'sales',
          component: () => import('@/pages/clientsPages/SalesPage/SalesPage.vue'),
          name: ROUTE_NAMES.Sales,
        },
        {
          path: 'my-points',
          component: () => import('@/pages/clientsPages/MyPointsPage/MyPointsPage.vue'),
          name: ROUTE_NAMES.MyPoints,
        },
      ],
    },
    {
      path: '/admin',
      component: () => import('@/layouts/AdminLayout/AdminLayout.vue'),
      redirect: { name: ROUTE_NAMES.AdminDashboard },
      children: [
        {
          path: 'profile',
          component: () => import('@/pages/adminPages/UserProfile/UserProfile.vue'),
          name: ROUTE_NAMES.AdminProfile,
          meta: {
            title: 'Профиль',
          },
        },
        {
          path: 'participants',
          component: () => import('@/pages/adminPages/ParticipantPage/ParticipantPage.vue'),
          name: ROUTE_NAMES.Participants,
        },
        {
          path: 'partners',
          component: () => import('@/pages/adminPages/PartnersPage/PartnersPage.vue'),
          name: ROUTE_NAMES.Partners,
        },
        {
          path: 'sale-points-groups',
          component: () => import('@/pages/adminPages/SalePointsGroups/SalePointsGroups.vue'),
          name: ROUTE_NAMES.SalePointsGroups,
        },
        {
          path: 'sales',
          component: () => import('@/pages/adminPages/AdminSalesPage/AdminSalesPage.vue'),
          name: ROUTE_NAMES.AdminSales,
        },
        {
          path: 'nomenclatures',
          component: () => import('@/pages/adminPages/NomenclaturePage/NomenclaturePage.vue'),
          name: ROUTE_NAMES.NomenclaturePage,
        },
        {
          path: 'promotions',
          component: () => import('@/pages/adminPages/PromotionPage/PromotionPage.vue'),
          name: ROUTE_NAMES.AdminPromotions,
        },
        {
          path: 'dashboard',
          component: () => import('@/pages/adminPages/AdminDashboardPage/AdminDashboardPage.vue'),
          name: ROUTE_NAMES.AdminDashboard,
        },
        {
          path: 'logs',
          component: () => import('@/pages/adminPages/ProcessReports/ProcessReports.vue'),
          name: ROUTE_NAMES.LogReports,
        },
        {
          path: 'logs/:id',
          component: () => import('@/pages/adminPages/LogsReports/LogsReports.vue'),
          name: ROUTE_NAMES.LogDetail,
        },
        {
          path: 'transactions',
          component: () => import('@/pages/adminPages/TransactionsPage/TransactionsPage.vue'),
          name: ROUTE_NAMES.Transactions,
        },
        {
          path: 'tech-users',
          component: () => import('@/pages/adminPages/AdminTechUsersPage/AdminTechUsersPage.vue'),
          name: ROUTE_NAMES.AdminTechUsersPage,
        },
        {
          path: 'access-roles',
          component: () => import('@/pages/adminPages/AccessRolesPage/AccessRolesPage.vue'),
          name: ROUTE_NAMES.AccessRolesPage,
        },
        {
          path: 'manufactures',
          component: () => import('@/pages/adminPages/ManufacturersPage/ManufacturersPage.vue'),
          name: ROUTE_NAMES.ManufacturesPage,
        },
        {
          path: 'support-requests',
          component: () => import('@/pages/adminPages/SupportRequestsPage/SupportRequestsPage.vue'),
          name: ROUTE_NAMES.SupportRequestsPage,
        },
        {
          path: 'brands',
          component: () => import('@/pages/adminPages/BrandsPage/BrandsPage.vue'),
          name: ROUTE_NAMES.BrandsPage,
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/layouts/ErrorLayout/ErrorLayout.vue'),
      children: [
        {
          path: '/:pathMatch(.*)*',
          name: ROUTE_NAMES.ErrorPage404,
          component: () => import('@/pages/ErrorPage404.vue'),
        },
        {
          path: '/500',
          name: ROUTE_NAMES.ErrorPage500,
          component: () => import('@/pages/ErrorPage500.vue'),
        },
      ],
    },
    {
      path: '/email-confirm',
      component: () => import('@/pages/EmailConfirm/EmailConfirm.vue'),
      name: ROUTE_NAMES.EmailConfirm,
    },
  ],
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
