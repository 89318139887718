import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import { Router } from 'vue-router'

export const initSentry = (app: App<Element>, router: Router) => {
  const dsn = import.meta.env.VITE_SENTRY_CLIENT_DSN
  const sentryName = import.meta.env.VITE_SENTRY_ENV_NAME
  const propagationTarget = import.meta.env.VITE_SENTRY_PROPAGATION_TARGET
  const authToken = import.meta.env.VITE_SENTRY_AUTH_TOKEN

  if (!dsn && !sentryName && !propagationTarget && !authToken) return

  Sentry.init({
    app,
    dsn,
    environment: `front:${sentryName}:${import.meta.env.DEV ? 'development' : 'production'}`,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracingOptions: {
      trackComponents: true,
    },
    maxBreadcrumbs: 50,
    debug: import.meta.env.DEV,
    attachProps: true,
    attachStacktrace: true,
    tracesSampleRate: 0.5,
    tracePropagationTargets: [new RegExp(propagationTarget)],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    normalizeDepth: 20,
  })

  app.config.errorHandler = (error) => {
    Sentry?.captureException(error, (scope) => {
      scope.setExtra('errorHandler', error)

      return scope
    })
  }
}
