import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const loggingListAccess = computed(() => {
  return checkPermissionHandler('logs', 'logsList')
})

const processesAccess = computed(() => {
  return checkPermissionHandler('logs', 'processList')
})

export const loggingFactory = () => {
  return {
    loggingListAccess: loggingListAccess,
    processesAccess: processesAccess,
  }
}
