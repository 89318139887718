import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const techSupportListAccess = computed(() => {
  return checkPermissionHandler('technicalSupport', 'technicalSupportList')
})

const techSupportAccess = computed(() => {
  return checkPermissionHandler('technicalSupport', 'detailRequestTechnicalSupport')
})

export const techSupportFactory = () => {
  return {
    techSupportListAccess: techSupportListAccess,
    techSupportAccess: techSupportAccess,
  }
}
