// Методы-валидаторы для полей форм
import { FormItemRule } from 'element-plus'

export const validateCompanyInnLength: FormItemRule['validator'] = (rule, value, callback) => {
  if (value.length === 10 || value.length === 12) {
    callback()
  } else {
    callback(new Error('Поле должно содержать 10 либо 12 цифр'))
  }
}

export const validatePassportSeriesLength: FormItemRule['validator'] = (rule, value, callback) => {
  if (value.length === 4) {
    callback()
  } else {
    callback(new Error('Поле должно содержать 4 цифры'))
  }
}

export const validatePassportNumberLength: FormItemRule['validator'] = (rule, value, callback) => {
  if (value.length === 6) {
    callback()
  } else {
    callback(new Error('Поле должно содержать 6 цифр'))
  }
}

export const validateSnilsLength: FormItemRule['validator'] = (rule, value, callback) => {
  if (value.length === 14) {
    callback()
  } else {
    callback(new Error('Поле должно содержать 11 цифр'))
  }
}

export const validateNumber: FormItemRule['validator'] = (rule, value, callback) => {
  const regex = /\D/

  if (!value.match(regex)) {
    callback()
  } else {
    callback(new Error('Поле должно содержать только целое число'))
  }
}
