import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const techUserListAccess = computed(() => {
  return checkPermissionHandler('technicalUserPage', 'technicalUserList')
})

const techUserAccess = computed(() => {
  return checkPermissionHandler('technicalUserPage', 'technicalUserCard')
})

export const techUserFactory = () => {
  return {
    techUserListAccess: techUserListAccess,
    techUserAccess: techUserAccess,
  }
}
