import { promotionsFactory } from '@/factories/Promotions/Promotions.factory'
import { salesFactory } from '@/factories/Sales/Sales.factory'
import { transactionsFactory } from '@/factories/Transactions/Transactions.factory'
import { rolesFactory } from '@/factories/Roles/Roles.fabric'
import { loggingFactory } from '@/factories/Logging/Logging.factory'
import { productsFactory } from '@/factories/Products/Products.factory'
import { membersFactory } from '@/factories/Members/Members.factory'
import { partnersFactory } from '@/factories/Partners/Partners.fabric'
import { salePointsGroupFactory } from '@/factories/SalePointsGroups/SalePointsGroups.factory'
import { techUserFactory } from '@/factories/TechUsers/TechUsers.factory'
import { manufacturersFactory } from '@/factories/Manufacturers/Manufacturers.factory'
import { techSupportFactory } from '@/factories/TechSupport/TechSupport.factory'
import { brandsFactory } from '@/factories/Brands/Brands.factory'
import { computed } from 'vue'
import { useUser } from '@/composables/useUser/useUser'
import { adminDashboardFactory } from '@/factories/AdminDashboard/AdminDashboard.fabric'

const { user } = useUser()

const { promotionDetailUserAccess, promotionsUserListAccess, promotionsListAccess } = promotionsFactory()
const { salesUserListAccess, salesAdminListAccess } = salesFactory()
const { userPointsTableDataAccess, outputPointsListAccess } = transactionsFactory()
const { rolesShowEmptyMatrixAccess } = rolesFactory()
const { processesAccess, loggingListAccess } = loggingFactory()
const { productsListAccess } = productsFactory()
const { participantsListAccess } = membersFactory()
const { partnersListAccess } = partnersFactory()
const { salePointsGroupListAccess } = salePointsGroupFactory()
const { techUserListAccess } = techUserFactory()
const { manufacturerListAccess } = manufacturersFactory()
const { techSupportListAccess } = techSupportFactory()
const { brandsListAccess } = brandsFactory()
const { summaryProductAccess, summaryMemberAndPointsAccess, summaryTransactionAccess } = adminDashboardFactory()

const adminDashboardAvailability = computed(() => {
  return (
    user.value?.roleCode !== 'member' &&
    (summaryProductAccess.value.read || summaryMemberAndPointsAccess.value.read || summaryTransactionAccess.value.read)
  )
})

const availableMenu = computed(() => {
  return {
    // User`s
    Profile: ['member', 'admin'].includes(user.value?.roleCode as string),
    Nomenclature: ['member'].includes(user.value?.roleCode as string),
    Promotions: promotionsUserListAccess.value.read,
    PromotionDetail: promotionDetailUserAccess.value.read,
    Dashboard: ['member', 'admin'].includes(user.value?.roleCode as string),
    Sales: salesUserListAccess.value.read,
    MyPoints: userPointsTableDataAccess.value.read,
    // Admin`s
    AccessRolesPage: rolesShowEmptyMatrixAccess.value.read,
    LogReports: processesAccess.value.read,
    LogDetail: loggingListAccess.value.read,
    NomenclaturePage: productsListAccess.value.read,
    Participants: participantsListAccess.value.read,
    Partners: partnersListAccess.value.read,
    SalePointsGroups: salePointsGroupListAccess.value.read,
    Transactions: outputPointsListAccess.value.read,
    AdminSales: salesAdminListAccess.value.read,
    AdminProfile: user.value?.roleCode !== 'member',
    AdminDashboard: adminDashboardAvailability.value,
    AdminPromotions: promotionsListAccess.value.read,
    AdminTechUsersPage: techUserListAccess.value.read,
    ManufacturesPage: manufacturerListAccess.value.read,
    SupportRequestsPage: techSupportListAccess.value.read,
    BrandsPage: brandsListAccess.value.read,

    ErrorPage404: true,
    ErrorPage500: true,
    EmailConfirm: true,
    Login: true,
    Registration: true,
    RestorePassword: true,
  }
})

export const menuFabric = () => {
  return {
    availableMenu,
  }
}
