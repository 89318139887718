import { AxiosRequestConfig } from 'axios'
import { MemberFormDataType } from '@/components/organism/adminPages/details/ParticipantDetail/ParticipantDetail.type'
import { API_CONFIG, AxiosService } from '@/plugins/axiosService'
import {
  ProfileDataType,
  ProfileUserType,
  UserDataType,
  PartnersItemType,
  GetSalePointsResponseType,
  CreateEditPayloadType,
  CreatePartnerType,
  SalePointItem,
  PartnerDataType,
  PartnerEditPayloadType,
  InternalUserDataType,
  ManufacturerTableItemType,
  ManufacturerCreateType,
  ManufacturerEditType,
  GetTechUsersListResponseType,
  UsersDictionaryType,
  EditTechUserPayloadType,
} from '@/api/UserService/UserService.type'
import { GetTableDataPayloadType } from '@/types/filter.type'
import { SalesPointsGroupDetailType } from '@/types/salesPointsGroup.type'
import { ManufacturersItemType } from '@/types/manufacturers.type'
import { PaginationType } from '@/types/pagination.type'

class UsersApi extends AxiosService {
  constructor(config?: AxiosRequestConfig) {
    super(config)
  }
  // dictionary
  getDictionary = async () => {
    return this.axiosCall<UsersDictionaryType>({
      method: 'get',
      url: '/v1/public/users/dictionary',
    })
  }

  // phone
  checkPhone = async (code: string) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/users/users/confirm-phone-code',
      data: {
        code,
      },
    })
  }

  confirmPhone = async (payload: string) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/users/users/phone/confirm-update',
      data: {
        code: payload,
      },
    })
  }

  editPhone = async (payload: string) => {
    return await this.axiosCall({
      method: 'put',
      url: '/v1/private/users/users/phone',
      data: {
        phone: payload,
      },
    })
  }

  verificationPhone = async (payload: string) => {
    return await this.axiosCall({
      method: 'get',
      url: '/v1/private/users/users/send-phone-code',
      params: {
        phone: payload,
      },
    })
  }

  getCompanyByInn = async (payload: string, role: string) => {
    return await this.axiosCall({
      method: 'get',
      url: '/v1/public/users/stores/addresses/by-inn',
      params: {
        inn: payload,
        role: role,
      },
    })
  }

  // email
  editEmail = async (payload: string) => {
    return await this.axiosCall({
      method: 'put',
      url: '/v1/private/users/users/email',
      data: {
        email: payload,
      },
    })
  }

  verifyEmail = async () => {
    return await this.axiosCall({
      method: 'get',
      url: 'v1/private/users/users/send-email-code',
    })
  }

  confirmEmail = async (code: string) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/users/users/confirm-email-code',
      data: {
        code,
      },
    })
  }

  // passport
  addPassportData = async (payload: FormData) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/users/users/passport',
      data: payload,
    })
  }

  editPassportData = async (payload: FormData) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/users/users/passport/update',
      data: payload,
    })
  }

  editProfileImage = async (payload: FormData) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/users/users/avatar',
      data: payload,
    })
  }

  // profile
  getUserProfileInfo = async () => {
    return await this.axiosCall<ProfileDataType>({
      method: 'get',
      url: '/v1/private/users/users/profile',
    })
  }

  editFullName = async (payload: Pick<ProfileUserType, 'surname' | 'name' | 'patronymic'>) => {
    return await this.axiosCall({
      method: 'put',
      url: '/v1/private/users/users/full-name',
      data: payload,
    })
  }

  // role??
  getUserPermissions = async () => {
    return await this.axiosCall<UserDataType>({
      method: 'get',
      url: '/v1/private/role/user/accesses',
    })
  }

  // participant
  editMember = async (payload: Partial<MemberFormDataType>, id: null | number) => {
    return await this.axiosCall({
      method: 'put',
      url: `/v1/private/users/admin/users/${id}`,
      data: payload,
    })
  }

  exportMembers = async () => {
    return await this.axiosCall({
      method: 'get',
      url: '/v1/private/users/admin/users/export',
    })
  }

  // partners
  getPartners = async (payload: GetTableDataPayloadType) => {
    return await this.axiosCall<{ items: PartnersItemType[]; pagination: PaginationType }>({
      method: 'post',
      url: '/v1/private/users/users/partner/list',
      data: payload,
    })
  }

  addPartner = async (payload: CreatePartnerType) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/auth/admin/register/partner',
      data: payload,
    })
  }

  addSomePartnersFile = async (payload: FormData) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/users/admin/users/mass-upload',
      data: payload,
    })
  }

  getPartner = async (id: string) => {
    return await this.axiosCall<PartnerDataType>({
      method: 'get',
      url: `/v1/private/users/users/partner/${id}`,
    })
  }

  editPartner = async (payload: PartnerEditPayloadType, id: number) => {
    return await this.axiosCall({
      method: 'put',
      url: `/v1/private/users/admin/users/partner/${id}`,
      data: payload,
    })
  }

  getPartnersStores = async (payload: ProfileDataType) => {
    return await this.axiosCall({
      method: 'put',
      url: `/v1/internal/users/${payload.user.userExternalId}/partner-store`,
      data: payload,
    })
  }

  // Группа точек продаж
  getSalePointList = async () => {
    return await this.axiosCall<SalePointItem[]>({
      method: 'get',
      url: '/v1/private/users/store-groups/short-list',
    })
  }

  // group sale points
  getSalePoints = async (payload: GetTableDataPayloadType) => {
    return await this.axiosCall<GetSalePointsResponseType>({
      method: 'post',
      url: '/v1/private/users/store-groups/list',
      data: payload,
    })
  }

  getSalePointDetail = async (id: number) => {
    return await this.axiosCall<SalesPointsGroupDetailType>({
      method: 'get',
      url: `/v1/private/users/store-groups/${id}`,
    })
  }

  createSalePointGroups = async (payload: CreateEditPayloadType) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/users/store-groups',
      data: payload,
    })
  }

  editSalePointGroups = async (id: number, payload: CreateEditPayloadType) => {
    return await this.axiosCall({
      method: 'put',
      url: `/v1/private/users/store-groups/${id}`,
      data: payload,
    })
  }

  findStoreByCompanyName = async (name: string) => {
    return await this.axiosCall<SalesPointsGroupDetailType['stores']>({
      method: 'get',
      url: '/v1/private/users/stores/find-by-company-name',
      params: { name },
    })
  }

  // Получение коротких данных о пользователе для страницы Транзакций
  getShortUserDataByPhone = async (phone: string) => {
    return this.axiosCall<InternalUserDataType>({
      method: 'get',
      url: '/v1/private/users/admin/users/info-by-phone',
      params: { phone: phone },
    })
  }

  // manufacturer
  getManufacturerFullList = async () => {
    return await this.axiosCall<ManufacturersItemType[]>({
      method: 'get',
      url: '/v1/private/users/companies/manufacturers',
    })
  }

  getManufacturers = async (payload: GetTableDataPayloadType) => {
    return this.axiosCall<{ items: ManufacturerTableItemType[]; pagination: PaginationType }>({
      method: 'post',
      url: '/v1/private/users/users/manufacturer/list',
      data: payload,
    })
  }

  addManufacturer = async (payload: ManufacturerCreateType) => {
    return this.axiosCall({
      method: 'post',
      url: '/v1/private/auth/admin/register/manufacturer',
      data: payload,
    })
  }

  editManufacturer = async (payload: ManufacturerEditType, id: string | number | null) => {
    return this.axiosCall({
      method: 'put',
      url: `/v1/private/users/admin/users/manufacturer/${id}`,
      data: payload,
    })
  }

  getManufacturer = async (id: string | number) => {
    return this.axiosCall<PartnerDataType>({
      method: 'get',
      url: `/v1/private/users/users/manufacturer/${id} `,
    })
  }

  // techUsers
  getTechUsersList = async (payload: GetTableDataPayloadType) => {
    return await this.axiosCall<GetTechUsersListResponseType>({
      method: 'post',
      url: '/v1/private/users/users/technical/list',
      data: payload,
    })
  }

  getTechUserDetail = async (id: number) => {
    return await this.axiosCall<ProfileDataType>({
      method: 'get',
      url: `/v1/private/users/users/technical/${id} `,
    })
  }

  editTechUser = async (id: number, payload: EditTechUserPayloadType) => {
    return await this.axiosCall({
      method: 'put',
      url: `/v1/private/users/admin/users/technical/${id}`,
      data: payload,
    })
  }

  passportReload = async (id: number) => {
    return await this.axiosCall({
      method: 'post',
      url: `/v1/private/users/admin/users/resendPassport/${id}`,
    })
  }
}

export default new UsersApi(API_CONFIG)
