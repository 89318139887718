import { useUser } from '@/composables/useUser/useUser'

const { pagePermissions } = useUser()

export type AccessResultDataType = {
  [key: string]: boolean
  read: boolean
  update: boolean
  create: boolean
  delete: boolean
}

export const checkPermissionHandler = (page: string, code: string) => {
  const accessResult: AccessResultDataType = {
    read: false,
    update: false,
    create: false,
    delete: false,
  }

  if (pagePermissions.value) {
    if (pagePermissions.value[page]) {
      const promotionsAccessesUrls = pagePermissions.value[page].find((item) => item.code === code)?.urls

      Object.keys(accessResult).forEach((key, index) => {
        const access = promotionsAccessesUrls?.find((url) => url.actionId === index + 1)

        if (typeof access?.access !== 'undefined') {
          accessResult[key] = access.access
        }
      })
    }
  }

  return accessResult
}
