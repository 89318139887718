import { AxiosRequestConfig } from 'axios'
import { API_CONFIG, AxiosService } from '@/plugins/axiosService'
import {
  ChangePasswordType,
  LoginPayloadType,
  RegistrationPayloadType,
  ResetPasswordType,
} from '@/api/AuthService/AuthService.type'
import Cookies from 'js-cookie'
import { EditTechUserPayloadType } from '@/api/UserService/UserService.type'

class AuthServiceApi extends AxiosService {
  constructor(config?: AxiosRequestConfig) {
    super(config)
  }

  refreshToken = async () => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/public/auth/refresh',
      headers: {
        refreshtoken: Cookies.get('refreshtoken'),
      },
    })
  }

  registerUser = async (payload: RegistrationPayloadType) => {
    return await this.axiosCall<RegistrationPayloadType>({
      method: 'post',
      url: '/v1/public/auth/register',
      data: payload,
    })
  }

  login = async (payload: LoginPayloadType) => {
    return await this.axiosCall<LoginPayloadType>({
      method: 'post',
      url: '/v1/public/auth/login',
      data: payload,
    })
  }

  // todo: вернуть, если бэку понадобится. А так - неактуальный запрос
  // logout = async () => {
  //   return await this.axiosCall<LoginPayloadType>({
  //     method: 'post',
  //     url: '/v1/private/auth/logout',
  //   })
  // }

  recoveryPassword = async (payload: string) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/public/auth/recovery_password',
      data: {
        email: payload,
      },
    })
  }

  changePassword = async (payload: ChangePasswordType) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/public/auth/change_password',
      data: payload,
    })
  }

  resetPassword = async (payload: ResetPasswordType) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/auth/users/reset-password',
      data: payload,
    })
  }

  createTechUser = async (payload: EditTechUserPayloadType) => {
    return await this.axiosCall({
      method: 'post',
      url: '/v1/private/auth/admin/register/technical',
      data: payload,
    })
  }
}

export default new AuthServiceApi(API_CONFIG)
