import { readonly, ref } from 'vue'

const loader = ref<boolean>(false)

export const useGlobalLoader = () => {
  const setLoader = (value: boolean) => {
    loader.value = value
  }

  return {
    loader: readonly(loader),
    setLoader,
  }
}
