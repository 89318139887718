<template>
  <suspense>
    <el-config-provider :locale="locale">
      <router-view v-loading="loader" />
    </el-config-provider>
  </suspense>
</template>

<script setup lang="ts">
import localeRU from 'element-plus/lib/locale/lang/ru'
import { ElConfigProvider } from 'element-plus'
import { useGlobalLoader } from '@/composables/useGlobalLoader/useGlobalLoader'

const locale = localeRU

const { loader } = useGlobalLoader()
</script>
