import { FormItemRule } from 'element-plus'
import {
  validateCompanyInnLength,
  validatePassportNumberLength,
  validatePassportSeriesLength,
  validateSnilsLength,
  validateNumber,
} from '@/utils/validators'

// WARNING: при добавлении новых страниц в обязательном порядке нужно добавлять их в фабрику меню
export const ROUTE_NAMES = {
  // Auth/Reg
  Login: 'Login',
  Registration: 'Registration',
  RestorePassword: 'RestorePassword',
  // User`s
  Profile: 'Profile',
  Promotions: 'Promotions',
  PromotionDetail: 'PromotionDetail',
  Dashboard: 'Dashboard',
  Sales: 'Sales',
  MyPoints: 'MyPoints',
  // Admin`s
  AccessRolesPage: 'AccessRolesPage',
  LogReports: 'LogReports',
  LogDetail: 'LogDetail',
  Nomenclature: 'Nomenclature',
  NomenclaturePage: 'NomenclaturePage',
  Participants: 'Participants',
  Partners: 'Partners',
  SalePointsGroups: 'SalePointsGroups',
  Transactions: 'Transactions',
  AdminSales: 'AdminSales',
  AdminProfile: 'AdminProfile',
  AdminDashboard: 'AdminDashboard',
  AdminPromotions: 'AdminPromotions',
  AdminTechUsersPage: 'AdminTechUsersPage',
  ManufacturesPage: 'ManufacturesPage',
  SupportRequestsPage: 'SupportRequestsPage',
  BrandsPage: 'BrandsPage',
  // Errors
  ErrorPage404: 'ErrorPage404',
  ErrorPage500: 'ErrorPage500',

  EmailConfirm: 'EmailConfirm',
} as const

export const MASKS = {
  phoneDataMask: '+7 (###) ###-##-##',
  passportSeriesMask: '####',
  passportNumberMask: '######',
  innMask: '############',
  snilsMask: '###-###-###-##',
  kppMask: '#########',
  cardMask: '#### #### #### ####',
  outputPoints: '#####',
} as const

export const FORM_RULES: Record<string, FormItemRule> = {
  required: { required: true, message: 'Обязательное поле', trigger: 'blur' },
  cyrillic: { pattern: /^[А-ЯЁа-яё]*$/, message: 'Поле может содержать только буквы кириллицы', trigger: 'blur' },
  email: { type: 'email', message: 'Введите корректный почтовый адрес', trigger: 'blur' },
  innLength: { validator: validateCompanyInnLength, trigger: 'blur' },
  passportSeriesLength: { validator: validatePassportSeriesLength, trigger: 'blur' },
  passportNumberLength: { validator: validatePassportNumberLength, trigger: 'blur' },
  snilsLength: { validator: validateSnilsLength, trigger: 'blur' },
  password: {
    pattern: /(?=.*[!@#/$%^&*])(?=.*[0-9])(?=.*[a-zа-я])(?=.*[A-ZА-Я])/g,
    message: 'Пароль должен содержать хотя бы одно число, спецсимвол, одну прописную букву и одну заглавную букву',
    trigger: 'blur',
  },
  passwordLength: {
    min: 8,
    message: 'Пароль должен содержать не менее 8 символов',
    trigger: 'blur',
  },
  phoneLength: { len: 18, message: 'Поле должно содержать 11 чисел', trigger: 'blur' },
  storeKppLength: { len: 9, message: 'Поле должно содержать 9 чисел', trigger: 'blur' },
  isNumberOnly: { validator: validateNumber, trigger: 'blur' },
} as const

export const DATE_FORMATS = {
  dayMonth: 'dd MMMM',
  dateDotted: 'dd.MM.yyyy',
  dateTimeFull: 'dd.MM.yyyy HH:mm:ss',
  dateTimeFullWithoutSeconds: 'dd MMMM yyyy HH:mm',
  dateWithFullMonth: 'dd MMMM yyyy',
  dateTimeFullMonth: 'dd MMMM yyyy HH:mm:ss',
  api: 'yyyy-MM-dd HH:mm:ss.SSS',
  atom1: 'yyyy-MM-dd',
  atom2: 'HH:mm:ssxxx',
  picker: 'yyyy-MM-dd 00:00:00.000',
} as const
