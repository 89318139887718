import { reactive } from 'vue'

function calculateSizes(screen: number) {
  if (screen >= 1440) return 'xl'
  else if (screen >= 1024) return 'lg'
  else if (screen >= 768) return 'md'
  else return 'sm'
}

export type ScreenVariableType = {
  width: number
  size: 'xl' | 'lg' | 'md' | 'sm'
}

const screen: ScreenVariableType = reactive({
  width: window.innerWidth,
  size: calculateSizes(window.innerWidth),
})

window.addEventListener('resize', () => {
  screen.width = window.innerWidth

  screen.size = calculateSizes(window.innerWidth)
})

export default screen
