import * as Sentry from '@sentry/vue'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { getFingerprint } from '@/plugins/fingerprint'
// @ts-ignore
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'
import { useUser } from '@/composables/useUser/useUser'

const { setAuthorized, setUser, setProfile, user } = useUser()

export type ApiResponseType<T = unknown[]> = {
  data: T
}

type ApiErrorType<T = unknown> = {
  data: T
  errors: any
  messages: string[]
  success: false
}

export type ServiceResponseType<T, E = unknown> = Promise<
  [null, AxiosResponse<ApiResponseType<T>>] | [AxiosError<ApiErrorType<E>>]
>

export class AxiosService {
  private axiosInstance!: AxiosInstance

  constructor(config?: AxiosRequestConfig) {
    this.axiosInstance = axios.create(config)

    /** Request handler */
    this.axiosInstance.interceptors.request.use(async (config: any) => {
      config.headers.fingerprint = await getFingerprint()

      config.headers['X-REQUEST-ID'] = uuidv4()

      const token = Cookies.get('sessionToken')

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    })

    /** Response handler */
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return Promise.resolve(response)
      },
      async (error) => {
        Sentry.captureException(
          {
            name: 'requestError',
            message: error.request,
          },
          (scope) => {
            scope.setTags({ frontPart: 'API' })

            scope.setExtras({
              error,
              user: user.value,
            })

            return scope
          }
        )

        switch (error?.response?.status) {
          case 401:
            const AuthService = await import('@/api/AuthService/AuthService').then((response) => response.default)

            if (!Cookies.get('refreshtoken')) break

            const [errorRefresh, responseRefresh] = await AuthService.refreshToken()

            if (!errorRefresh) {
              if (responseRefresh.headers.token) {
                Cookies.set('sessionToken', responseRefresh?.headers.token)
              }

              if (responseRefresh.headers.refreshtoken) {
                Cookies.set('refreshtoken', responseRefresh.headers.refreshtoken)
              }

              const config = error.config

              config.headers.refreshtoken = Cookies.get('refreshtoken')

              config.headers.sessiontoken = Cookies.get('sessionToken')

              ElMessage.success('Время сеанса продлено.')

              const [e, res] = await this.axiosCall(config)

              if (e) break

              return res
            } else {
              // отправить на страницу авторизации.

              ElMessage.error('Время сеанса истекло. Требуется авторизация.')

              Cookies.remove('sessionToken')

              Cookies.remove('refreshtoken')

              await setAuthorized(false)

              await setUser(undefined)

              await setProfile(undefined)

              const router = await import('@/router').then((response) => response.default)

              const ROUTE_NAMES = await import('@/constants').then((response) => response.ROUTE_NAMES)

              await router.push({ name: ROUTE_NAMES.Login })

              return
            }

          case 403:
            break

          default:
            if (error.response.data.messages.length) {
              error.response.data.messages.forEach((item: string) => {
                ElMessage.error(item)
              })

              break
            }

            if (typeof error.response.data.errors[0] === 'object') {
              error.response.data.errors.forEach((item: Record<string, string[]>) => {
                Object.values(item).forEach((messages) => {
                  messages.forEach((value) => {
                    ElMessage.error(value)
                  })
                })
              })
            } else {
              error.response.data.errors.forEach((item: string) => {
                ElMessage.error(item)
              })
            }

            break
        }

        return Promise.reject(error)
      }
    )
  }

  protected async axiosCall<T = any>(config: AxiosRequestConfig): ServiceResponseType<T> {
    try {
      const response = await this.axiosInstance.request<ApiResponseType<T>>(config)

      return [null, response]
    } catch (error: any) {
      return [error as AxiosError<ApiErrorType>]
    }
  }
}

export const getBaseUrl = (): string => {
  return import.meta.env.DEV ? '/api' : [import.meta.env.VITE_BASE_URL, '/api'].join('')
}

export const API_CONFIG: AxiosRequestConfig = {
  baseURL: getBaseUrl(),
  withCredentials: true,
  timeout: 600000,
}
