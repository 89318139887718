import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const participantsListAccess = computed(() => {
  return checkPermissionHandler('memberPage', 'memberList')
})

const participantDetailAccess = computed(() => {
  return checkPermissionHandler('memberPage', 'memberCard')
})

const exportParticipantsAccess = computed(() => {
  return checkPermissionHandler('memberPage', 'exportMember')
})

export const membersFactory = () => {
  return {
    participantsListAccess: participantsListAccess,
    participantDetailAccess: participantDetailAccess,
    participantsExportAccess: exportParticipantsAccess,
  }
}
