import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const salePointsGroupListAccess = computed(() => {
  return checkPermissionHandler('storesGroups', 'storesGroupsList')
})

const salePointsGroupAccess = computed(() => {
  return checkPermissionHandler('storesGroups', 'storesGroupsDetail')
})

export const salePointsGroupFactory = () => {
  return {
    salePointsGroupListAccess: salePointsGroupListAccess,
    salePointsGroupAccess: salePointsGroupAccess,
  }
}
