import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const massUploadPartnerAccess = computed(() => {
  return checkPermissionHandler('partner', 'massUploadPartner')
})

const partnersListAccess = computed(() => {
  return checkPermissionHandler('partner', 'partnerList')
})

const partnerDetailAccess = computed(() => {
  return checkPermissionHandler('partner', 'partnerDetail')
})

export const partnersFactory = () => {
  return {
    massUploadPartnerAccess: massUploadPartnerAccess,
    partnersListAccess: partnersListAccess,
    partnerDetailAccess: partnerDetailAccess,
  }
}
