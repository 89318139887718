import {
  ElAlert,
  ElMessage,
  ElDatePicker,
  ElTimePicker,
  ElForm,
  ElFormItem,
  ElInput,
  ElUpload,
  ElDialog,
  ElNotification,
  ElSelect,
  ElOption,
  ElOptionGroup,
  ElRow,
  ElCol,
  ElButton,
  ElMenu,
  ElMenuItem,
  ElIcon,
  ElCheckbox,
  ElCheckboxGroup,
  ElRadio,
  ElRadioGroup,
  ElProgress,
  ElScrollbar,
  ElLoading,
  ElInfiniteScroll,
  ElImage,
  ElPopover,
  ElPopconfirm,
  ElTooltip,
  ElPagination,
  ElTable,
  ElTableColumn,
} from 'element-plus'
import { App } from 'vue'

const ElComponents = [
  ElAlert,
  ElDatePicker,
  ElTimePicker,
  ElInput,
  ElUpload,
  ElSelect,
  ElOption,
  ElOptionGroup,
  ElRow,
  ElCol,
  ElButton,
  ElMenu,
  ElMenuItem,
  ElIcon,
  ElScrollbar,
  ElCheckbox,
  ElCheckboxGroup,
  ElRadio,
  ElForm,
  ElFormItem,
  ElRadioGroup,
  ElProgress,
  ElImage,
  ElPopover,
  ElPopconfirm,
  ElTooltip,
  ElPagination,
  ElTable,
  ElTableColumn,
]

const ElPlugins = [ElNotification, ElDialog, ElMessage, ElLoading, ElInfiniteScroll]

export function useElementPlus(app: App<Element>) {
  ElPlugins.forEach((plugin) => app.use(plugin))

  ElComponents.forEach((component) => app.component(component.name, component))
}
