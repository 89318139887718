import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const rolesShowEmptyMatrixAccess = computed(() => {
  return checkPermissionHandler('roleSystem', 'showEmptyMatrixAccess')
})

const rolesDetailAccess = computed(() => {
  return checkPermissionHandler('roleSystem', 'detailAccessesRole')
})

export const rolesFactory = () => {
  return {
    rolesShowEmptyMatrixAccess: rolesShowEmptyMatrixAccess,
    rolesDetailAccess: rolesDetailAccess,
  }
}
