import router from '@/router'
import Cookies from 'js-cookie'
import UsersApi from '@/api/UserService/UserService'
import { useUser } from '@/composables/useUser/useUser'
import { useRules } from '@/composables/useRules/useRules'
import AuthService from '@/api/AuthService/AuthService'
import { menuFabric } from '@/factories/AvailableMenu/AvailableMenu.factory'
import { ROUTE_NAMES } from '@/constants'
import { useGlobalLoader } from '@/composables/useGlobalLoader/useGlobalLoader'
import AppSettingsService from '@/api/AppSettingsService/AppSettingsService'

const { setLoader } = useGlobalLoader()

const { rules, setProgramRules } = useRules()

const { isAuthorized, setUser, setAuthorized, userMainPage, setProfile, profile } = useUser()

const whiteList: string[] = [ROUTE_NAMES.Login, ROUTE_NAMES.Registration, ROUTE_NAMES.RestorePassword]

const { availableMenu } = menuFabric()

const getProgramRules = async () => {
  if (rules.value) return

  const [error, response] = await AppSettingsService.getProgramRules()

  if (error) return

  if (response.data.data?.file) {
    setProgramRules(response.data.data.file)
  }
}

const getUserProfileInfo = async () => {
  const [error, response] = await UsersApi.getUserProfileInfo()

  if (error) return

  setProfile(response.data.data)
}

export const usePermission = async () => {
  router.beforeEach(async (to, from, next) => {
    if (from.name !== to.name) setLoader(true)

    if (
      (profile.value?.user?.isPhoneVerified === false || profile.value?.user?.status === 'moderation') &&
      !whiteList.includes(to.name as unknown as string)
    ) {
      Cookies.remove('sessionToken')

      Cookies.remove('refreshtoken')
    }

    await getProgramRules()

    if (
      from.name === ROUTE_NAMES.Registration &&
      to.name === ROUTE_NAMES.Login &&
      profile.value?.user.status !== 'confirmed'
    ) {
      Cookies.remove('sessionToken')

      Cookies.remove('refreshtoken')
    }

    const [error, response] = await UsersApi.getUserPermissions()

    if (!error) {
      await getUserProfileInfo()

      setUser(response.data.data)

      await setAuthorized(true)
    } else {
      if (!whiteList.includes(to.name as unknown as string)) {
        const [err, res] = await AuthService.refreshToken()

        if (!err) {
          if (res.headers.token) {
            Cookies.set('sessionToken', res?.headers.token)
          }

          if (res.headers.refreshtoken) {
            Cookies.set('refreshtoken', res.headers.refreshtoken)
          }

          next()

          return
        } else {
          await setAuthorized(false)

          return next({ name: ROUTE_NAMES.Login })
        }
      }
    }

    if (!isAuthorized.value) {
      if (whiteList.includes(to.name as unknown as string)) {
        next()
      } else {
        return next({ name: ROUTE_NAMES.Login })
      }
    } else {
      if (to.name === ROUTE_NAMES.Login) {
        return next({ name: userMainPage.value })
      } else {
        // @ts-ignore
        if (!availableMenu.value[to.name]) return next({ name: ROUTE_NAMES.ErrorPage404 })

        return next()
      }
    }
  })

  router.afterEach(() => {
    setLoader(false)
  })
}

await usePermission()
