import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const manufacturerListAccess = computed(() => {
  return checkPermissionHandler('manufacturer', 'manufacturerList')
})

const manufacturerDetailAccess = computed(() => {
  return checkPermissionHandler('manufacturer', 'manufacturerDetail')
})

export const manufacturersFactory = () => {
  return {
    manufacturerListAccess: manufacturerListAccess,
    manufacturerDetailAccess: manufacturerDetailAccess,
  }
}
