import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

// Получения суммарной статистики номенклатуры
const getSummaryProductAccess = computed(() => {
  return checkPermissionHandler('dashboard', 'getSummaryProduct')
})

// Получения суммарной кол-во участников и баллов
const getSummaryMemberAndPointsAccess = computed(() => {
  return checkPermissionHandler('dashboard', 'getSummaryMemberAndPoints')
})

// Получения статистики по транзакциям
const getSummaryTransactionAccess = computed(() => {
  return checkPermissionHandler('dashboard', 'getSummaryTransaction')
})

export const adminDashboardFactory = () => {
  return {
    summaryProductAccess: getSummaryProductAccess,
    summaryMemberAndPointsAccess: getSummaryMemberAndPointsAccess,
    summaryTransactionAccess: getSummaryTransactionAccess,
  }
}
