import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const productsListAccess = computed(() => {
  return checkPermissionHandler('products', 'productList')
})

const productAccess = computed(() => {
  return checkPermissionHandler('products', 'productDetail')
})

const massLoadProductsAccess = computed(() => {
  return checkPermissionHandler('products', 'productImport')
})

export const productsFactory = () => {
  return {
    productsListAccess: productsListAccess,
    productAccess: productAccess,
    massLoadProductsAccess: massLoadProductsAccess,
  }
}
