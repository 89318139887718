import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

const salesListAccess = computed(() => {
  return checkPermissionHandler('sales', 'salesListAdmin')
})

const salesUserListAccess = computed(() => {
  return checkPermissionHandler('sales', 'salesListMember')
})

const salesAdminListAccess = computed(() => {
  return checkPermissionHandler('sales', 'salesListAdmin')
})

const saleAccess = computed(() => {
  return checkPermissionHandler('sales', 'sale')
})

export const salesFactory = () => {
  return {
    salesListAccess,
    salesAdminListAccess,
    saleAccess,
    salesUserListAccess,
  }
}
