import { computed } from 'vue'
import { checkPermissionHandler } from '@/factories'

// Получение текущих баллов юзеров getCurrentUserPoints (myPointsPage)
const adminGetPointsUserAccess = computed(() => {
  return checkPermissionHandler('transactionPage', 'getPointsUser')
})
// Получение списка корректировок баллов getCorrectionPoints
const adminTuningsTransactionList = computed(() => {
  return checkPermissionHandler('transactionPage', 'tuningsTransactionList')
})
// Получение данных для деталки вывода баллов getOutputPointsDetailData
const adminDetailTransactionAccess = computed(() => {
  return checkPermissionHandler('transactionPage', 'detailTransaction')
})
// Получение данных для модалки корректировки баллов getCorrectionPointsDetailData
const adminTuningsTransactionAccess = computed(() => {
  return checkPermissionHandler('transactionPage', 'tuningsTransaction')
})
// Получение данных для таблицы вывода баллов Админа getOutputPoints
const adminTransactionListAdminAccess = computed(() => {
  return checkPermissionHandler('transactionPage', 'transactionListAdmin')
})
// Запрос на получение short-данных о пользователе User.service getShortUserDataByPhone
const adminShortUserInfoByPhoneAccess = computed(() => {
  return checkPermissionHandler('transactionPage', 'shortUserInfoByPhone')
})
// Получение данных для таблицы Баллов пользователя getUserPointsTableData ( myPointsPage )
const adminTransactionListMemberAccess = computed(() => {
  return checkPermissionHandler('transactionPage', 'transactionListMember')
})

export const transactionsFactory = () => {
  return {
    currentUserPointsAccess: adminGetPointsUserAccess,
    correctionPointsList: adminTuningsTransactionList,
    outputPointsDetailDataAccess: adminDetailTransactionAccess,
    correctionPointsDetailDataAccess: adminTuningsTransactionAccess,
    outputPointsListAccess: adminTransactionListAdminAccess,
    shortUserDataByPhoneAccess: adminShortUserInfoByPhoneAccess,
    userPointsTableDataAccess: adminTransactionListMemberAccess,
  }
}
